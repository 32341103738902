import React from "react"
import cx from "classnames"
import styles from "./TitleBlock.module.css"

export const TitleBlock = ({ className, children, isWhite, isColored, isLarger, ...props }) => (
  <h2
    {...props}
    className={cx({ [styles.root]: !isColored, [styles.white]: isWhite }, props.className, {
      [styles.colored]: isColored, [styles.largerfont]: isLarger,
    })}
  >
    {children}
  </h2>
)

export default TitleBlock
