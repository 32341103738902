import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "./WhatWeDo.module.css"
import styles from "./Resource.module.css"
import HubspotForm from "components/HubspotForm"


// eslint-disable-next-line
export const ResourcePage = ({ data, footerImage, divideImage, ...props }) => (
  <Layout {...props} {...data}>
    <Container className={`${altStyles.wrapper} ${styles.contentWrapper}`}>
      <div className={styles.contentContainer}>
        <p className={altStyles.bannertitle}>{data.bannerTitle}</p>
        {data.image ? (
        <div className={styles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            key={data.title}
            alt={data.title}
            title={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        </div>
        ) : null}
        <Title className={`${altStyles.title} ${altStyles.titlesmaller}`}>
          <span dangerouslySetInnerHTML={{ __html: data.title || ""}} />
        </Title>
        <div className={`${altStyles.description} ${styles.description}`} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
      </div>
      {data.form && data.portalId ? (
      <div className={styles.formContainer} id="contact-form">
        <Container>
          <TitleBlock>Download Today!</TitleBlock>
          <HubspotForm id={data.form} portalId={data.portalId} />
        </Container>
      </div>
    ) : null}
    </Container>
  </Layout>
)

// eslint-disable-next-line
export const ResourcePageWrapper = (props) => (
  <ResourcePage
    {...props}
    data={props.data.markdownRemark.frontmatter}
  />
)

export default ResourcePageWrapper

export const pageQuery = graphql`
  query ResourcePage($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        id
        title
        description
        bannerTitle
        bannerButtonText
        bannerButtonUrl
        formTitle
        form
        portalId
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logo {
          childImageSharp {
            fluid(maxWidth: 120, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
