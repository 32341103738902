import React from "react"
import cx from "classnames"
import styles from "./Title.module.css"

export const Title = ({ className, children, ...props }) => (
  <h1 className={cx(className, styles.root)} {...props}>
    {children}
  </h1>
)

export default Title
